/**
 * Feature flag set for the Conflicts application.
 *
 * NOTE: these flags are in camel case to match the react.useCamelCaseFlagKeys option
 * set in the applications ProviderConfig.
 */
export type ConflictsFlagSet = {
    hitOwner: boolean;
    tagging: boolean;
    autoAssignTo: boolean;
    defaultFuzzySearch: boolean;
    stickyRefiners: boolean;
    sanctionsList: boolean;
};

export const defaultLDFeatureFlags: ConflictsFlagSet = { hitOwner: false, tagging: false, autoAssignTo: false, defaultFuzzySearch: false, stickyRefiners: false, sanctionsList: false };
