import { Table, TableHead, TableRow, TableCell, TableBody, Divider, Stack } from "@mui/material";
import { FirmSettings, SanctionsLists } from "aderant-conflicts-models";
import { aderantColors, AderantTheme, Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, MessageDialog } from "@aderant/aderant-react-components";
import React from "react";
import { EntitySpreadsheetImport } from "./EntitySpreadsheetImport";
import { conflictsPalette } from "styles/conflictsPalette";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Messages } from "pages/FirmSettingsPage/FirmSettingsSections/SanctionsSection/Messages";

type ListRowType = { displayName: string; listType: FirmSettings.SanctionsListType; lastUpdateCompleted: Date };
//ToDo - US59617: This is the first pass at the Sanctions Import page. It will need to be updated to match the final design.
export const SanctionsTable = () => {
    const [entityName, setEntityName] = React.useState<string>("");
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isImportBtnClicked, setImportBtnClicked] = React.useState<boolean>(false);
    const [dialogBoxImportId, setDialogBoxImportId] = React.useState<string | null>(null);

    function importList(id: string | null): void {
        console.log("Import button clicked");
        setImportBtnClicked(false);
        // const currentTarget = e.currentTarget;
        if (!id) {
            console.error("No id found on target. Import button does not have id. This is needed to determine which data to import");
            return;
        }
        if (!id.startsWith("import-")) {
            console.error("Invalid id found on target .Valid id is needed to determine which data to import. Id does not start with import-. Id: " + id);
            return;
        }
        if (SanctionsLists[id.substring(7)] !== id.substring(7)) {
            console.error("Invalid id found on target. Valid id is needed to determine which data to import. List type not found. Id: " + id);
            return;
        }
        setEntityName(`sanctions-${id.substring(7)}`);
        setIsOpen(true);
    }
    const deleteList = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const currentTarget = e.currentTarget;
        if (!currentTarget.id) {
            console.error("No id found on target. Delete button does not have id. This is needed to determine which data to delete");
            return;
        }
        if (!currentTarget.id.startsWith("delete-")) {
            console.error("Invalid id found on target .Valid id is needed to determine which data to delete. Id does not start with delete-. Id: " + currentTarget.id);
            return;
        }
        if (SanctionsLists[e.currentTarget.id.substring(7)] !== e.currentTarget.id.substring(7)) {
            console.error("Invalid id found on target. Valid id is needed to determine which data to delete. List type not found. Id: " + currentTarget.id);
            return;
        }
        //dispatch(adminActions.deleteSanctionsList({ entityName: `sanctions-${e.currentTarget.id.substring(7)}` }));
    };

    function ImportTableRow(sanctionsLists: ListRowType[]) {
        return sanctionsLists.map((row: ListRowType, index: number) => (
            <TableRow
                key={row.listType}
                className="sanctions-list-row"
                sx={{
                    fontSize: AderantTheme.typography.pxToRem(16),
                    color: aderantColors.Ahsoka,
                    borderBottom: index === sanctionsLists.length - 1 ? "none" : `1px solid rgba(229, 229, 229)`,
                    td: {
                        padding: "0.2rem 0rem"
                    },
                }}
            >
                <TableCell sx={{ color: '#5F7286' }}> {row.displayName}</TableCell>
                <TableCell sx={{ color: '#5F7286' }}>{row.lastUpdateCompleted?.toLocaleString(undefined, { hour12: false })}</TableCell>
                <TableCell align="right">
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <span
                            id={`import-${row.listType}`}
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", color:"#1379CE" }}
                            onClick={(e) => {
                                setDialogBoxImportId(e.currentTarget.id);
                                setImportBtnClicked(true);
                            }}
                        >
                            <CloudUploadOutlinedIcon  style={{ marginRight: "5px", color:"#1379CE", height:"20px", width:"20px" }} />
                            Import
                        </span>
                        <span id={`delete-${row.listType}`} style={{ display: "flex", alignItems: "center", cursor: "pointer", color:"#CC0000" }}>
                            <DeleteOutlineOutlinedIcon style={{ color:"#CC0000", height:"20px", width:"20px" }} />
                            Delete
                        </span>
                    </Stack>
                    {/* <Button id={`import-${row.listType}`} color="primary" aria-label={`Import ${row.displayName}`} text="Import" onClick={importList} /> */}
                </TableCell>
                {/* <Divider /> */}
            </TableRow>
        ));
    }

    const sanctionsLists: ListRowType[] = [
        {
            displayName: FirmSettings.SanctionsMessages.EU_LIST.getMessage(),
            listType: SanctionsLists.eu,
            lastUpdateCompleted: new Date()
        },
        {
            displayName: FirmSettings.SanctionsMessages.OFAC_LIST.getMessage(),
            listType: SanctionsLists.ofac,
            lastUpdateCompleted: new Date()
        },
        {
            displayName: FirmSettings.SanctionsMessages.UK_LIST.getMessage(),
            listType: SanctionsLists.uk,
            lastUpdateCompleted: new Date()
        } 
    ];
    return (
        <>
            <Table style={{ minWidth: 650, margin: "0 38px", width: "94%" }}>
                <TableHead sx={{ backgroundColor: conflictsPalette.background.white, color: conflictsPalette.text.black, border: 0 }}>
                    <TableRow
                        key={"sanctions-list-table-header"}
                        sx={{
                            th: {
                                fontSize: AderantTheme.typography.pxToRem(14),
                                color: "rgb(51, 51, 51)",
                                padding: "0 0rem",
                                fontWeight: 600
                            },
                            border: 0,
                        }}
                    >
                        <TableCell>{FirmSettings.SanctionsMessages.SANCTIONS_LIST.getMessage()}</TableCell>
                        <TableCell>{FirmSettings.SanctionsMessages.LAST_IMPORTED.getMessage()}</TableCell>
                        <TableCell align="right" style={{ visibility: "hidden" }}>
                            {FirmSettings.SanctionsMessages.IMPORT.getMessage()}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{ImportTableRow(sanctionsLists)}</TableBody>
            </Table>
            {/* <MessageDialog
                open={isImportBtnClicked}
                onClose={() => setImportBtnClicked(false)}
                message={Messages.PROCEED_IMPORT_PROMPT.getMessage()}
                title={Messages.PROCEED_IMPORT_TITLE.getMessage()}
                footer={
                    <>
                        <Button
                            text="No"
                            color="secondary"
                            onClick={() => {
                                setImportBtnClicked(false);
                            }}
                        />
                        <Button text="Yes" color="primary" onClick={() => importList(dialogBoxImportId)} />
                    </>
                }
            /> */}
            <Dialog open={isImportBtnClicked} onClose={() => setImportBtnClicked(false)} size="sm" aria-labelledby="test">
                <DialogTitle>
                    <DialogHeader>{Messages.PROCEED_IMPORT_TITLE.getMessage()}</DialogHeader>
                </DialogTitle>
                <DialogContent style={{ whiteSpace: "pre-line" }}>
                    <>
                        <div>
                            <div style={{ width: "100%" }}>
                                <span>{Messages.PROCEED_IMPORT_PROMPT.getMessage()}</span>
                            </div>
                        </div>
                    </>
                </DialogContent>
                <DialogFooter>
                    <>
                        <Button
                            text="No"
                            color="secondary"
                            onClick={() => {
                                setImportBtnClicked(false);
                            }}
                        />
                        <Button text="Yes" color="primary" onClick={() => importList(dialogBoxImportId)} />
                    </>
                </DialogFooter>
            </Dialog>

            {isOpen && <EntitySpreadsheetImport entityName={entityName} isOpen={isOpen} setIsOpen={setIsOpen} />}
        </>
    );
};
