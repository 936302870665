import { getDefinitionDisplayName } from "./FirmSettings";
import { ApiKeyManagementDefinition, FirmOptionsDefinition, InformationBarriersDefinition, IntegrationDefinition, DataImportsDefinition, SynonymManagementDefinition } from "./PageDefinitions/index";
import { FirmSettingsPageDefinition, FirmSettingsSectionBasicDefinition, FirmSettingsSectionDefinition } from "./PageDefinitions/InternalTypes/FirmSettingsPageDefinition";

export const isPageBasic = <T extends PageDefinition>(pageDefinition: T): pageDefinition is BasicPage<T> => {
    if (pageDefinition.sections.some((section: SectionDefinition) => section.type === "handwritten")) {
        return false;
    }
    return true;
};

export * from "./PageDefinitions";
const pageDefinitions = [FirmOptionsDefinition, SynonymManagementDefinition, InformationBarriersDefinition, ApiKeyManagementDefinition, IntegrationDefinition, DataImportsDefinition] as const;

/** Page definitions sorted by displayName */
export const sortedPageDefinitions = [...pageDefinitions].sort((a, b) => getDefinitionDisplayName(a).localeCompare(getDefinitionDisplayName(b)));
export const basicPageDefinitions = sortedPageDefinitions.filter(isPageBasic);

//reexporting these types derived from the definitions rather than the ones in FirmSettingsPageDefinition.ts so that code can statically deal with all possible pages.
export type PageDefinition = typeof sortedPageDefinitions[number];
export type SectionDefinition = PageDefinition["sections"][number];
export type PageName = PageDefinition["name"];

type BasicSection<S extends SectionDefinition> = S extends { type: "basic" } ? S : never;
type HandwrittenSection<S extends SectionDefinition> = S extends { type: "handwritten" } ? S : never;

export type BasicSectionDefinition = BasicSection<SectionDefinition>;
export type HandwrittenSectionDefinition = HandwrittenSection<SectionDefinition>;

export type FieldDefinition = BasicSectionDefinition["fields"][number];

type BasicPage<P extends PageDefinition> = P extends { sections: readonly BasicSection<SectionDefinition>[] } ? P : never;

export type BasicPageDefinition = BasicPage<PageDefinition>;
export type PageDefinitionName = PageDefinition["name"];

//See: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
//Distributing typing across a union is a feature of conditional types, so the extends syntax has to be included for this to work.
type SectionValidFieldPath<T extends FirmSettingsSectionDefinition> = T extends FirmSettingsSectionBasicDefinition ? `${T["name"]}/${T["fields"][number]["name"]}` : never;

type PageValidFieldPath<T extends FirmSettingsPageDefinition> = T extends T ? `${T["name"]}/${SectionValidFieldPath<T["sections"][number]>}` : never;

export type FieldPath = PageValidFieldPath<PageDefinition>;

//the following is for deriving the stored data structure from the page definitions.
//=============================================================
type FindByName<Field extends { name: string }, Name extends string> = Field extends { name: Name } ? Field : never;

type FieldData<T extends FieldDefinition> = T["type"] extends "boolean" ? boolean : T["type"] extends "options" ? { label: string; value: string } : never;
type SectionData<T extends SectionDefinition> = T extends BasicSection<SectionDefinition> ? { [key in T["fields"][number]["name"]]: FieldData<FindByName<T["fields"][number], key>> } : never;
type PageData<T extends PageDefinition> = T extends BasicPage<T> ? { [key in T["sections"][number]["name"]]: SectionData<FindByName<T["sections"][number], key>> } : never;

export type PageDataModel = {
    [key in BasicPage<PageDefinition>["name"]]: PageData<FindByName<PageDefinition, key>>;
};
//=============================================================

//Following adapted from https://stackoverflow.com/questions/71096136/how-to-implement-a-gettypebypath-type-in-typescript
//but simplified as we know we're only dealing with string indexes all the way to the leaf node.
//
//This essentially recursively indexes T by the part of the path up until the first '/', and then passes that part of T and the remaining part of the path down to the next iteration (IndexByPath<T[F], R>).
//This continues until it bottoms out on the end of the path that does not contain any more '/'s, and then it returns the type of that leaf node (T[K])
type IndexByPath<T extends Record<string, any>, K extends string> = K extends `${infer F}/${infer R}` ? IndexByPath<T[F], R> : T[K];

/**Gives the type of the data for the given field path */
export type FieldType<Path extends FieldPath> = IndexByPath<PageDataModel, Path>;

export * from "./FirmSettings";
export * from "./Messages";

export function getFirmSettingsPathParts(path: FieldPath) {
    const [page, section, field] = path.split("/");
    return { page, section, field };
}
