import { ConflictsFlagSet, LaunchDarklyClient } from "aderant-conflicts-models";
import { LDContext } from "@launchdarkly/node-server-sdk";

function getConflictsFlagSetKey(key: string): keyof ConflictsFlagSet {
    switch (key) {
        case "hit_owner":
            return "hitOwner";
        case "tagging":
            return "tagging";
        case "auto_assign_to":
            return "autoAssignTo";
        case "default_fuzzy_search":
            return "defaultFuzzySearch";
        case "sticky_refiners":
            return "stickyRefiners";
        case "sanctions_list":
            return "sanctionsList";
    }
    throw new Error(`Unknown flag key: ${key}`);
}
function getFlagValue(flags: ConflictsFlagSet, key: string, defaultValue: unknown): unknown {
    const flagKey = getConflictsFlagSetKey(key);
    return flags[flagKey] ?? defaultValue;
}
function getBooleanFlagValue(flags: ConflictsFlagSet, key: string, defaultValue: boolean): boolean {
    const value = getFlagValue(flags, key, defaultValue);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value && typeof value === "boolean" ? (value as boolean) : defaultValue;
}
function getStringFlagValue(flags: ConflictsFlagSet, key: string, defaultValue: string): string {
    const value = getFlagValue(flags, key, defaultValue);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value && typeof value === "string" ? (value as string) : defaultValue;
}
function getNumberFlagValue(flags: ConflictsFlagSet, key: string, defaultValue: number): number {
    const value = getFlagValue(flags, key, defaultValue);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value ? (isNaN(value as unknown as number) ? defaultValue : (value as number)) : defaultValue;
}
function getJSONFlagValue(flags: ConflictsFlagSet, key: string, defaultValue: JSON): JSON {
    const value = getFlagValue(flags, key, defaultValue);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value ? (JSON.parse(value as string) as JSON) : defaultValue;
}
export function getMockLaunchDarklyClient(flags: ConflictsFlagSet): LaunchDarklyClient {
    const mockedLDCLient: LaunchDarklyClient = {
        variation: jest.fn().mockImplementation((key: string, context: LDContext, defaultValue: any) => Promise.resolve(getFlagValue(flags, key, defaultValue))),
        boolVariation: jest.fn().mockImplementation((key: string, context: LDContext, defaultValue: boolean) => Promise.resolve(getBooleanFlagValue(flags, key, defaultValue))),
        stringVariation: jest.fn().mockImplementation((key: string, context: LDContext, defaultValue: string) => Promise.resolve(getStringFlagValue(flags, key, defaultValue))),
        numberVariation: jest.fn().mockImplementation((key: string, context: LDContext, defaultValue: number) => Promise.resolve(getNumberFlagValue(flags, key, defaultValue))),
        jsonVariation: jest.fn().mockImplementation((key: string, context: LDContext, defaultValue: JSON) => Promise.resolve(getJSONFlagValue(flags, key, defaultValue))),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        waitForInitialization: () => undefined as unknown as Promise<LaunchDarklyClient>,
        initialized: jest.fn().mockReturnValue(true)
    };
    mockedLDCLient.waitForInitialization = () => Promise.resolve(mockedLDCLient);
    return mockedLDCLient;
}
