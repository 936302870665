import { hasString } from "../TypeUtils/TypeGuards";
import * as RSI from "@aderant/react-spreadsheet-import/types/types";
import { Columns } from "@aderant/react-spreadsheet-import/types/steps/MatchColumnsStep/MatchColumnsStep";

export type BaseColumnDefinition = {
    isSearchable: boolean;
    propertyLabel: string;
    propertyName: string;
};

export type ImportColumnDefinition = BaseColumnDefinition & {
    importMapping: string;
    alternateMatches?: string[];
    fieldType?: RSI.Checkbox | RSI.Select | RSI.Input;
    validations?: RSI.Validation[];
};

export const isImportColumnDefinition = (column: ColumnDefinition): column is ImportColumnDefinition => {
    return hasString(column, "importMapping");
};

export type DerivedColumnDefinition = BaseColumnDefinition & {
    derivedColumnMapping: string[];
};

export type ColumnDefinition = ImportColumnDefinition | DerivedColumnDefinition;

export interface ImportEntityTemplate {
    entityName: string;
    entityDisplayName: string;
    columns: ColumnDefinition[];
}

//ToDo - US59617: This is the first pass at the data to post. It will need to be updated to match the final design.
//data includes validData, invalidData and all, with all containing both validData and invalidData. Probably don't need all 3, as this has duplication. Adjust as needed by endpoint.
export type ImportDataToPost = {
    entityName: string;
    data: RSI.Result<string>;
    file: File;
    columnMapping: Columns<string>;
};
