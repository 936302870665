import { Message } from "aderant-conflicts-models";

export const Messages = {
    //Sanction Modal messages
    PROCEED_IMPORT_PROMPT: new Message(
        "PROCEED_IMPORT_PROMPT",
        "Importing data changes entities, which may affect search requests that are being created or in progress. We recommend importing data outside of regular operating hours. \r\n\r\n If importing new data when data already exists, the existing data will be deleted and replaced. \r\n\r\n Do you wish to continue?"
    ),
    PROCEED_IMPORT_TITLE: new Message("PROCEED_IMPORT_TITLE", "Proceed with Import?"),
    PENDING_CHANGES_TITLE: new Message("PENDING_CHANGES_TITLE", "Unsaved Changes"),
    PENDING_CHANGES_PROMPT: new Message("PENDING_CHANGES_PROMPT", "Changes that you made may not be saved, leave the page?"),
    IMPORT_FILE_ERROR_TITLE: new Message("IMPORT_FILE_ERROR_TITLE", "Import Failure"),
    IMPORT_FILE_ERROR_PROMPT: new Message("IMPORT_FILE_ERROR_PROMPT", "The Sanctions {sanctions_type} data file is too large."),
    IMPORT_RETRY_FAILURE_TITLE: new Message("IMPORT_FILE_ERROR_TITLE", "Import Failure"),
    IMPORT_RETRY_FAILURE_PROMPT: new Message("IMPORT_FILE_ERROR_PROMPT", "The Sanctions {sanctions_type} data import failed. Try again, and if the problem persists, contact your system administrator."),
    COPY_FAILED_TITLE: new Message("COPY_FAILED_TITLE", "Copy failed")
};
