import { Accordion } from "@aderant/aderant-react-components";
import React from "react";
import { FirmSettings } from "aderant-conflicts-models";
import { SanctionsTable } from "../SanctionsSection/components/SanctionsTable";
import { useFeatureFlags } from "hooks/useFeatureFlags";

export function DataImportsSection () {  

    // FEATURE-FLAG: santionsList
    const featureFlags = useFeatureFlags();
    const isSanctionsListEnabled = featureFlags.sanctionsList;
    console.info(
        `[FeatureFlags] sanctionsList: ${featureFlags.sanctionsList}`
    );

    return (
        <div>
            {isSanctionsListEnabled ?
                (<Accordion
                    title={FirmSettings.Messages.SANCTIONS_IMPORT.getMessage()}
                    defaultExpanded={true}
                    details= {<SanctionsTable />}
                />)
            : (<></>)}
        </div>
    )
}

