import { useFlags } from "launchdarkly-react-client-sdk";
import { ConflictsFlagSet, defaultLDFeatureFlags } from "aderant-conflicts-models";

export function useFeatureFlags(): ConflictsFlagSet {
    // Justification: This is the wrapper class implementation for the useFlags hook
    // eslint-disable-next-line no-restricted-syntax
    const flags = useFlags<ConflictsFlagSet>();
    return {
        hitOwner: flags.hitOwner ?? defaultLDFeatureFlags.hitOwner,
        tagging: flags.tagging ?? defaultLDFeatureFlags.tagging,
        autoAssignTo: flags.autoAssignTo ?? defaultLDFeatureFlags.autoAssignTo,
        defaultFuzzySearch: flags.defaultFuzzySearch ?? defaultLDFeatureFlags.defaultFuzzySearch,
        stickyRefiners: flags.stickyRefiners ?? defaultLDFeatureFlags.stickyRefiners,
        sanctionsList: flags.sanctionsList ?? defaultLDFeatureFlags.sanctionsList,
    };
}
