import { DefaultImportEntityTemplate, ImportColumnDefinition, ImportEntityTemplate, isImportColumnDefinition } from "aderant-conflicts-models";
import { ReactSpreadsheetImport } from "@aderant/react-spreadsheet-import";
import PageContent from "components/PageContainer/PageContent";
import React, { useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { adminActions } from "state/actions";
import * as RSI from "@aderant/react-spreadsheet-import/types/types";
import { Columns } from "@aderant/react-spreadsheet-import/types/steps/MatchColumnsStep/MatchColumnsStep";

export function mapImportColumnDefinition(columns: ImportColumnDefinition[]): RSI.Field<string>[] {
    const fields = columns.map((column) => {
        return {
            label: column.propertyLabel,
            key: column.propertyName,
            alternateMatches: column.alternateMatches || [],
            fieldType: column.fieldType || { type: "input" },
            validations: column.validations || []
        };
    });
    return fields;
}

export type EntitySpreadsheetImportProps = {
    // The name of the entity to import data for.
    entityName: string;
    // Determines if modal is visible.
    isOpen: boolean;
    // Use to update isOpen.
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
//TODO - US59978: This is the first pass at the EntitySpreadsheetImport UI. It will need to be updated to match the final design.  
export function EntitySpreadsheetImport(props: EntitySpreadsheetImportProps) {
    const { entityName, isOpen, setIsOpen } = props;
    const columnMapping = useRef<Columns<string>>([]);
    const dispatch = useDispatch();

    const handleSubmit = (data: RSI.Result<string>, file: File) => {
        console.log(data, file)
        dispatch(adminActions.saveSanctionsList({entityName, data, file, columnMapping: columnMapping.current}));
    };

    function handleClose() {
        if (setIsOpen) {
            setIsOpen(false);
        }
    }

    function afterMatchColumns(table: RSI.Data<string>[], rawData: RSI.RawData[], columns: Columns<string>): Promise<RSI.Data<string>[]> {
        columnMapping.current = columns;
        return Promise.resolve(table);
    }

    const fields = useMemo(() => {
        if (!entityName && isOpen) {
            handleClose();
        }
        const templates : ImportEntityTemplate[] = DefaultImportEntityTemplate.filter((t) => t.entityName === entityName);
        if (templates.length === 0) {
            console.log("No templates found for entity: " + entityName);
            handleClose();
        }
        const template = templates[0];
        const importColumns = template.columns.filter((c) => isImportColumnDefinition(c));
        // We know that importColumns is an array of ImportColumnDefinition, so we can safely cast it.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return mapImportColumnDefinition(importColumns as ImportColumnDefinition[]);
    }, [entityName]);

    if (fields.length === 0) {
        console.log("No import fields found in template for entity: " + entityName);
        handleClose();
    }
    return (
        <PageContent>
            <ReactSpreadsheetImport
                isOpen={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit}
                fields={fields}
                isNavigationEnabled={true}
                persistStateOnNavigation={true}
                matchColumnsStepHook={afterMatchColumns}
            />
        </PageContent>
    );
}
