import React from "react";
import { FirmSettings, PermissionsContextDirect } from "aderant-conflicts-models";
import { FirmSettingsSection, FirmSettingsSectionComponent } from "./FirmSettingsSection";
import { SynonymMapGrid } from "./SynonymMapSection/SynonymMapGrid";
import { ApiKeyComponent } from "./ApiKeySection/ApiKeyComponent";
import { InformationBarriersComponent } from "./InformationBarriersSection/InformationBarriersComponent";
import { YesNoField } from "components/DynamicFields/EditableFields/YesNoField";
import { ExpertIntegrationSection } from "./IntegrationSection/ExpertIntegrationSection";
import { OptionsField } from "components/DynamicFields/EditableFields/OptionsField";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { DataImportsSection } from "./SanctionsSection/DataImportsSection";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildPage(
    pageDefinition: FirmSettings.PageDefinition,
    onSectionChange: (pageName: string, sectionName: string, newData: Record<string, any>) => void,
    settingsData: Partial<FirmSettings.PageDataModel>
): FirmSettingsSection[] {
    const sections: FirmSettingsSection[] = [];
    //Check all page data is loaded, before displaying data
    for (const section of pageDefinition.sections) {
        sections.push(buildFirmSettingsSection(pageDefinition, section, (newData) => onSectionChange(pageDefinition.name, section.name, newData), settingsData));
    }
    return sections;
}

export function buildFirmSettingsSection(
    pageDefinition: FirmSettings.PageDefinition,
    sectionDefinition: FirmSettings.SectionDefinition,
    onSectionChange: (newData: Record<string, any>) => void,
    settingsData: Partial<FirmSettings.PageDataModel>
): FirmSettingsSection {
    switch (sectionDefinition.type) {
        case "basic": {
            return buildBasicFirmSettingsSection(pageDefinition, sectionDefinition, onSectionChange, settingsData);
        }
        case "handwritten": {
            return buildHandwrittenFirmSettingsSection(pageDefinition, sectionDefinition, onSectionChange);
        }
    }
}

function buildHandwrittenFirmSettingsSection(
    pageDefinition: FirmSettings.PageDefinition,
    sectionDefinition: FirmSettings.HandwrittenSectionDefinition,
    onSectionChange: (newData: FirmSettings.HandwrittenSectionDefinition["defaultValue"]) => void
): FirmSettingsSection {
    return {
        id: { pageName: pageDefinition.name, sectionName: sectionDefinition.name },
        sectionType: sectionDefinition.type,
        displayName: sectionDefinition.name,
        component: getComponentForHandwrittenSection(sectionDefinition),
        onSectionChange: onSectionChange
    };
}

function getComponentForHandwrittenSection<Section extends FirmSettings.HandwrittenSectionDefinition>(sectionDefinition: Section): FirmSettingsSectionComponent<Section["defaultValue"]> {
    //It is VERY important to return the correct type from this function.  You MUST return only the component, like we do here.  If it is wrapped in a function,
    //React is unable to resolve the type of the component, instead parsing it as an Anonymous component, which plays havoc with React state management when loaded.
    switch (sectionDefinition.name) {
        case "api-key-management": {
            return ApiKeyComponent;
        }
        case "synonym-management": {
            return SynonymMapGrid;
        }
        case "information-barriers": {
            return InformationBarriersComponent;
        }
        case "expert-integration": {
            return ExpertIntegrationSection;
        }
        case "sanctions-import": {
            return DataImportsSection;
        }
        default: {
            return () => <div>Component not available</div>;
        }     
    }
}

function buildBasicFirmSettingsSection(
    pageDefinition: FirmSettings.PageDefinition,
    sectionDefinition: FirmSettings.BasicSectionDefinition,
    onSectionChange: (newData: Record<string, any>) => void,
    settingsData: Partial<FirmSettings.PageDataModel>
): FirmSettingsSection {
    return {
        id: { pageName: pageDefinition.name, sectionName: sectionDefinition.name },
        sectionType: sectionDefinition.type,
        displayName: sectionDefinition.name,
        component: createComponentForBasicSection(sectionDefinition, settingsData),
        onSectionChange: onSectionChange
    };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createComponentForBasicSection(sectionDefinition: FirmSettings.BasicSectionDefinition, settingsData: Partial<FirmSettings.PageDataModel>): FirmSettingsSectionComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (props: { data: Record<string, any>; onSectionChange: (newData: Record<string, any>) => void; isPagePersisting: boolean }) => {
        const fields: JSX.Element[] = [];
        const isAutoAssignToFeatureEnabled = useFeatureFlags().autoAssignTo;
        const isDefaultFuzzySearchFeatureEnabled = useFeatureFlags().defaultFuzzySearch;
        for (const field of sectionDefinition.fields) {
            //Typescript won't let you use map on a type like [a] | [b] | [c], because the func being passed in to the map call can't accept a union with no commonality... or something.
            //We want to merge the changes made to a field along with the entire section data here.
            //This is so that onSectionChange has the complete set of updated data within that section.

            //Feature flag check for autoAssignToUserType and applyFuzzySearchByDefaultForSearchRequests.
            if ((field.name !== "autoAssignToUserType" || isAutoAssignToFeatureEnabled) && (field.name !== "applyFuzzySearchByDefaultForSearchRequests" || isDefaultFuzzySearchFeatureEnabled)) {
                fields.push(createField(field, props.data[field.name], settingsData, (value) => props.onSectionChange({ ...props.data, ...value }), props.isPagePersisting));
            }
        }

        if (fields.length === 0) {
            return null;
        }

        return (
            <div key={sectionDefinition.name} style={{ margin: "0.313rem", paddingBottom: "3.031rem" }}>
                <div style={{ marginTop: "3.031rem", marginLeft: "3.266rem", fontSize: 18, fontWeight: 600 }}>{FirmSettings.getDefinitionDisplayName(sectionDefinition)}</div>
                <div style={{ marginLeft: "2.626rem", marginTop: "1rem" }}>
                    {fields.map((field) => (
                        <div key={field.key} style={{ marginTop: "0.5rem" }}>
                            {field}
                        </div>
                    ))}
                </div>
            </div>
        );
    };
}

function createField(
    field: FirmSettings.FieldDefinition,
    data: any,
    settingsData: Partial<FirmSettings.PageDataModel>,
    onFieldChange: (newData: Record<string, any>) => void,
    isPagePersisting: boolean
): JSX.Element {
    switch (field.type) {
        case "boolean": {
            return YesNoField({ field: field, data: data, onChange: (checked: boolean) => onFieldChange({ [field.name]: checked }), isPagePersisting: isPagePersisting });
        }
        case "options": {
            return OptionsField({
                field: field,
                settingsData: settingsData,
                data: data,
                onChange: (value: { label: string; value: string }) => onFieldChange({ [field.name]: value }),
                isPagePersisting: isPagePersisting
            });
        }
        //These can be uncommented when implementation is required
        // case "string": {
        //     return TextInputField({ field: field, data: data, onChange: onChange });
        // }
        // case "integer": {
        //     return IntegerField({field: field, data: data, onChange: onChange});
        // }
    }
}
