import { ConflictsAction } from "../../Permissions/UserPermission";
import { satisfies } from "../../UtilityTypes";
import { Message } from "../../Validation/Message";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";

//ToDo - US59617: This is the first pass at the Sanctions Import page. It will need to be updated to match the final design.
export const SanctionsLists = {
    ofac: "ofac",
    eu: "eu",
    uk: "uk"
} as const;

export type SanctionsListType = typeof SanctionsLists[keyof typeof SanctionsLists];

export const SanctionsMessages = {
    OFAC_LIST: new Message("OFAC_LIST", "Sanctions OFAC"),
    UK_LIST: new Message("UK_LIST", "Sanctions UK"),
    EU_LIST: new Message("EU_LIST", "Sanctions EU"),
    SANCTIONS_LIST: new Message("SANCTIONS_LIST", "List Name"),
    LAST_IMPORTED: new Message("LAST_IMPORTED", "Last Successful Update Completed (Date, Time)"),
    IMPORT: new Message("IMPORT", "Import")
};

/**
 * "Information Barriers" is the UI name for row level security config.  This section has the bit that lets users select which fields to un-redact on specific rows
 */
const dataImportsSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "sanctions-import",
    displayName: Messages.DATA_IMPORT.getMessage(),
    defaultValue: {},
    type: "handwritten"
} as const);

export const DataImportsDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "sanctions-import",
    displayName: Messages.DATA_IMPORT.getMessage(),
    sections: [dataImportsSection],
    requiredPermission: ConflictsAction.ViewSettingsPage,
    dataToLoad: []
} as const);
