/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put } from "redux-saga/effects";
import { Action, parseError, Return, watch } from "./common";
import { FirmSettings, ok, SynonymMap, Forbidden, ImportDataToPost, Result, isConflictsError } from "aderant-conflicts-models";
import * as SagaContext from "../store/ConflictsSagaContext";
import { adminActions, AdminActionType } from "state/actions/AdminActions";
import { userAlertActions } from "state/actions/UserAlertActions";
import { Messages as InformationBarriersMessages } from "pages/FirmSettingsPage/FirmSettingsSections/InformationBarriersSection/Messages";
import { appActions } from "state/actions";

export const adminWatchers = [
    function* fetchIngestionStatusWatcher() {
        yield* watch(AdminActionType.FETCH_INGESTION_STATUS, fetchIngestionStatus);
    },

    function* fetchSynonymMapWatcher() {
        yield* watch(AdminActionType.FETCH_SYNONYM_MAP, fetchSynonymMap);
    },

    function* updateSynonymMapWatcher() {
        yield* watch(AdminActionType.UPDATE_SYNONYM_MAP, updateSynonymMap);
    },

    function* fetchSynonymsStatusWatcher() {
        yield* watch(AdminActionType.FETCH_SYNONYMS_STATUS, fetchSynonymsStatus);
    },

    function* updateSynonymsStatusWatcher() {
        yield* watch(AdminActionType.UPDATE_SYNONYMS_STATUS, updateSynonymsStatus);
    },

    function* fetchApiKeysWatcher() {
        yield* watch(AdminActionType.FETCH_API_KEYS, fetchApiKeys);
    },

    function* revokeApiKeyWatcher() {
        yield* watch(AdminActionType.REVOKE_API_KEY, revokeApiKey);
    },

    function* generateApiKeyWatcher() {
        yield* watch(AdminActionType.GENERATE_API_KEY, generateApiKey);
    },

    function* regenerateApiKeyWatcher() {
        yield* watch(AdminActionType.REGENERATE_API_KEY, regenerateApiKey);
    },

    function* fetchFirmSettingsWatcher() {
        yield* watch(AdminActionType.FETCH_FIRM_SETTINGS, fetchFirmSettings);
    },

    function* fetchFirmSettingsPageDataWatcher() {
        yield* watch(AdminActionType.FETCH_FIRM_SETTINGS_PAGE_DATA, fetchFirmSettingsPageData);
    },

    function* saveFirmSettingsPageDataWatcher() {
        yield* watch(AdminActionType.SAVE_FIRM_SETTINGS_PAGE_DATA, saveFirmSettingsPageData);
    },
    
    function* saveSanctionsListDataWatcher() {
        yield* watch(AdminActionType.SAVE_SANCTIONS_LIST, saveSanctionsList);
    },

    function* saveRlsColumnVisibilityWatcher() {
        yield* watch(AdminActionType.SAVE_RLS_COLUMN_VISIBILITY, saveRlsColumnVisibility);
    },

    function* fetchRlsColumnVisibilityWatcher() {
        yield* watch(AdminActionType.FETCH_RLS_COLUMN_VISIBILITY, fetchRlsColumnVisibility);
    }
];

function* fetchIngestionStatus() {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();
    try {
        yield put(adminActions.isFetching({ pageName: FirmSettings.IntegrationDefinition.name, dataName: "INGESTION_STATUS", isFetching: true }));
        const ingestionStatuses: Return<typeof adminService.getIngestionPipelineStatus> = yield adminService.getIngestionPipelineStatus();
        if (ok(ingestionStatuses)) {
            yield put(adminActions.fetchIngestionStatusSuccess(ingestionStatuses));
        } else {
            yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.IntegrationDefinition.name, dataName: "INGESTION_STATUS", error: ingestionStatuses.message }));
            yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.INTEGRATIONS.getMessage())));
        }
    } catch (error) {
        logger.error("Unable to fetch ingestion pipeline statuses", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.IntegrationDefinition.name, dataName: "INGESTION_STATUS", error: parseError(error) }));
        yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.INTEGRATIONS.getMessage())));
    } finally {
        yield put(adminActions.isFetching({ pageName: FirmSettings.IntegrationDefinition.name, dataName: "INGESTION_STATUS", isFetching: false }));
    }
}

function* fetchSynonymMap() {
    const searchService = yield* SagaContext.getSearchService();
    const logger = yield* SagaContext.getLogger();

    try {
        yield put(adminActions.isFetching({ pageName: FirmSettings.SynonymManagementDefinition.name, dataName: "SYNONYM_MAP", isFetching: true }));
        const synonymMap: Return<typeof searchService.getSynonymMap> = yield searchService.getSynonymMap();
        if (ok(synonymMap)) {
            yield put(adminActions.fetchSynonymMapSuccess(synonymMap));
        } else {
            yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.SynonymManagementDefinition.name, dataName: "SYNONYM_MAP", error: synonymMap.message }));
            yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.SYNONYM_MANAGEMENT.getMessage())));
        }
    } catch (error) {
        logger.error("Unable to fetch synonym map", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.SynonymManagementDefinition.name, dataName: "SYNONYM_MAP", error: parseError(error) }));
        yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.SYNONYM_MANAGEMENT.getMessage())));
    } finally {
        yield put(adminActions.isFetching({ pageName: FirmSettings.SynonymManagementDefinition.name, dataName: "SYNONYM_MAP", isFetching: false }));
    }
}

function* updateSynonymMap(action: Action<typeof AdminActionType.UPDATE_SYNONYM_MAP, { updatedSynonymMap: SynonymMap }>) {
    const searchService = yield* SagaContext.getSearchService();
    const logger = yield* SagaContext.getLogger();

    try {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.SynonymManagementDefinition.name, isPersisting: true }));
        const updatedSynonymMap: Return<typeof searchService.updateSynonymMap> = yield searchService.updateSynonymMap(action.payload.updatedSynonymMap);
        if (ok(updatedSynonymMap)) {
            yield put(adminActions.updateSynonymMapSuccess(updatedSynonymMap));
            yield put(userAlertActions.addUserAlert(`Synonym map updated.`, { type: "success" }));
        } else {
            yield put(adminActions.adminFailure(updatedSynonymMap.message));
            yield put(userAlertActions.addUserAlert(`Synonym map failed to update. ${updatedSynonymMap.message}`, { type: "error" }));
        }
    } catch (error) {
        logger.error("Unable to update synonym map", parseError(error));
        yield put(adminActions.adminFailure(parseError(error)));
        yield put(userAlertActions.addUserAlert(`Synonym map failed to update. Please try again.`, { type: "error" }));
    } finally {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.SynonymManagementDefinition.name, isPersisting: false }));
    }
}

function* fetchSynonymsStatus() {
    const searchService = yield* SagaContext.getSearchService();
    const logger = yield* SagaContext.getLogger();

    try {
        const synonymMapToggle: Return<typeof searchService.getSynonymsStatus> = yield searchService.getSynonymsStatus();
        if (ok(synonymMapToggle)) {
            yield put(adminActions.fetchSynonymsStatusSuccess(synonymMapToggle.synonymsStatus));
        } else {
            yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.SynonymManagementDefinition.name, dataName: "SYNONYMS_STATUS", error: synonymMapToggle.message }));
            yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.SYNONYM_MANAGEMENT.getMessage())));
        }
    } catch (error) {
        logger.error("Unable to fetch synonym map toggle status", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.SynonymManagementDefinition.name, dataName: "SYNONYMS_STATUS", error: parseError(error) }));
        yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.SYNONYM_MANAGEMENT.getMessage())));
    }
}

function* updateSynonymsStatus(action: Action<typeof AdminActionType.UPDATE_SYNONYMS_STATUS, { synonymMapToggle: "ON" | "OFF" }>) {
    const searchService = yield* SagaContext.getSearchService();
    const logger = yield* SagaContext.getLogger();

    try {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.SynonymManagementDefinition.name, isPersisting: true }));
        const synonymMapToggle: Return<typeof searchService.updateSynonymsStatus> = yield searchService.updateSynonymsStatus(action.payload.synonymMapToggle);
        if (ok(synonymMapToggle)) {
            yield put(adminActions.updateSynonymsStatusSuccess(synonymMapToggle.synonymsStatus));
            yield put(userAlertActions.addUserAlert(`Synonyms ${synonymMapToggle.synonymsStatus === "ON" ? "enabled" : "disabled"} successfully.`, { type: "success" }));
        } else {
            yield put(adminActions.adminFailure(synonymMapToggle.message));
            yield put(userAlertActions.addUserAlert(FirmSettings.Messages.FIRM_SETTINGS_SAVE_FAILED.getMessage(), { type: "error" }));
        }
    } catch (error) {
        logger.error("Unable to update indexes with synonym map toggle status", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFailure(parseError(error)));
        yield put(userAlertActions.addUserAlert(FirmSettings.Messages.FIRM_SETTINGS_SAVE_FAILED.getMessage(), { type: "error" }));
    } finally {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.SynonymManagementDefinition.name, isPersisting: false }));
    }
}

function* fetchApiKeys() {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();

    try {
        yield put(adminActions.isFetching({ pageName: FirmSettings.ApiKeyManagementDefinition.name, dataName: "API_KEYS", isFetching: true }));
        const apiKeys: Return<typeof adminService.getApiKeys> = yield adminService.getApiKeys();
        if (ok(apiKeys)) {
            yield put(adminActions.fetchApiKeysSucess(apiKeys));
        } else {
            yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.ApiKeyManagementDefinition.name, dataName: "API_KEYS", error: apiKeys.message }));
            yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.API_KEY_MANAGEMENT.getMessage())));
        }
    } catch (error) {
        logger.error("Unable to fetch API keys", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.ApiKeyManagementDefinition.name, dataName: "API_KEYS", error: parseError(error) }));
        yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.Messages.API_KEY_MANAGEMENT.getMessage())));
    } finally {
        yield put(adminActions.isFetching({ pageName: FirmSettings.ApiKeyManagementDefinition.name, dataName: "API_KEYS", isFetching: false }));
    }
}

function* revokeApiKey(action: Action<typeof AdminActionType.REVOKE_API_KEY, { uniqueKeyName: string }>) {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();
    try {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.ApiKeyManagementDefinition.name, isPersisting: true }));
        const revokeApiKey: Return<typeof adminService.revokeApiKey> = yield adminService.revokeApiKey(action.payload.uniqueKeyName);
        if (ok(revokeApiKey)) {
            yield put(adminActions.revokeApiKeySuccess(action.payload.uniqueKeyName));
        } else {
            yield put(adminActions.adminFailure(revokeApiKey.message));
            yield put(userAlertActions.addUserAlert("Unable to revoke API key. Please try again.", { type: "error" }));
        }
    } catch (error) {
        logger.error("Unable to revoke API key", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFailure(parseError(error)));
        yield put(userAlertActions.addUserAlert("Unable to revoke API key. Please try again.", { type: "error" }));
    } finally {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.ApiKeyManagementDefinition.name, isPersisting: false }));
    }
}

function* generateApiKey(action: Action<typeof AdminActionType.GENERATE_API_KEY, { uniqueKeyName: string }>) {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();
    try {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.ApiKeyManagementDefinition.name, isPersisting: true }));
        const generatedApiKey: Return<typeof adminService.createApiKey> = yield adminService.createApiKey(action.payload.uniqueKeyName);
        if (ok(generatedApiKey)) {
            yield put(adminActions.generateApiKeySuccess({ uniqueKeyName: action.payload.uniqueKeyName, generatedApiKey: generatedApiKey }));
        } else {
            yield put(adminActions.adminFailure(generatedApiKey.message));
            yield put(userAlertActions.addUserAlert("Unable to generate API key. Please try again.", { type: "error" }));
        }
    } catch (error) {
        logger.error("Unable to generate API key", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFailure(parseError(error)));
        yield put(userAlertActions.addUserAlert("Unable to generate API key. Please try again.", { type: "error" }));
    } finally {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.ApiKeyManagementDefinition.name, isPersisting: false }));
    }
}

function* regenerateApiKey(action: Action<typeof AdminActionType.REGENERATE_API_KEY, { uniqueKeyName: string }>) {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();
    try {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.ApiKeyManagementDefinition.name, isPersisting: true }));
        const regeneratedApiKey: Return<typeof adminService.regenerateApiKey> = yield adminService.regenerateApiKey(action.payload.uniqueKeyName);
        if (ok(regeneratedApiKey)) {
            yield put(adminActions.regenerateApiKeySuccess({ uniqueKeyName: action.payload.uniqueKeyName, generatedApiKey: regeneratedApiKey }));
        } else {
            yield put(adminActions.adminFailure(regeneratedApiKey.message));
            yield put(userAlertActions.addUserAlert("Unable to regenerate API key. Please try again.", { type: "error" }));
        }
    } catch (error) {
        logger.error("Unable to regenerate API key", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFailure(parseError(error)));
        yield put(userAlertActions.addUserAlert("Unable to regenerate API key. Please try again.", { type: "error" }));
    } finally {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.ApiKeyManagementDefinition.name, isPersisting: false }));
    }
}

function* fetchFirmSettings(settingsFieldPaths: Action<typeof AdminActionType.FETCH_FIRM_SETTINGS, FirmSettings.FieldPath[]>) {
    const logger = yield* SagaContext.getLogger();
    const adminService = yield* SagaContext.getAdminService();
    try {
        const fetchedSettings: Return<typeof adminService.getFirmSettingsByFieldPaths> = yield adminService.getFirmSettingsByFieldPaths(settingsFieldPaths.payload);

        if (isConflictsError(fetchedSettings)) {
            //ok doesn't work here, I think because the success type has arbitrary keys and the compiler thinks it could be an error.
            yield put(adminActions.adminFailure(`Unable to fetch firm settings: ${fetchedSettings.message}`));
            yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_LOAD_FAILED.getMessage()));
        } else {
            yield put(adminActions.fetchFirmSettingsSuccess(fetchedSettings));
        }
    } catch (error) {
        logger.error("Unable to fetch firm settings.", error);
        yield put(adminActions.adminFailure(`Unable to fetch firm settings: ${error}.`));
        yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_LOAD_FAILED.getMessage()));
    }
}

function* fetchFirmSettingsPageData(action: Action<typeof AdminActionType.FETCH_FIRM_SETTINGS_PAGE_DATA, { pageName: FirmSettings.PageDefinitionName }>) {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();
    const displayPageName = FirmSettings.getDisplayNameFromPageName(action.payload.pageName, FirmSettings.sortedPageDefinitions);
    try {
        yield put(adminActions.isFetching({ pageName: action.payload.pageName, dataName: "FIRM_SETTINGS_PAGE_DATA", isFetching: true }));
        const pageData: Return<typeof adminService.getFirmSettingsPageData> = yield adminService.getFirmSettingsPageData(action.payload.pageName);
        if (ok(pageData)) {
            yield put(adminActions.fetchFirmSettingsPageDataSuccess({ pageName: action.payload.pageName, pageData: pageData }));
        } else {
            yield put(
                adminActions.adminFetchFailure({
                    pageName: action.payload.pageName,
                    dataName: "FIRM_SETTINGS_PAGE_DATA",
                    error: `Firm settings for page ${action.payload.pageName} not found: ${pageData.message}`
                })
            );
            yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(displayPageName)));
        }
    } catch (error) {
        logger.error(`Unable to fetch page data for Firm Settings page ${action.payload.pageName}`, error instanceof Error ? error.message : JSON.stringify(error));
        yield put(
            adminActions.adminFetchFailure({
                pageName: action.payload.pageName,
                dataName: "FIRM_SETTINGS_PAGE_DATA",
                error: `Fetching firm settings for page ${action.payload.pageName}: ${parseError(error)}`
            })
        );
        yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(displayPageName)));
    } finally {
        yield put(adminActions.isFetching({ pageName: action.payload.pageName, dataName: "FIRM_SETTINGS_PAGE_DATA", isFetching: false }));
    }
}

function* saveFirmSettingsPageData(action: Action<typeof AdminActionType.SAVE_FIRM_SETTINGS_PAGE_DATA, { pageName: FirmSettings.PageDefinitionName; pageData: Record<string, Record<string, any>> }>) {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();

    const pageDefinition = FirmSettings.validateDefinitionExists(action.payload.pageName);
    if (!ok(pageDefinition)) {
        //This should never happen unless something has seriously gone wrong.
        logger.error(`Page definition for page ${action.payload.pageName} does not exist. Error: ${pageDefinition.message}`);
        yield put(adminActions.adminFailure(`Page ${action.payload.pageName} could not be found. See inner error for details.`));
        yield put(userAlertActions.addUserAlert(FirmSettings.Messages.FIRM_SETTINGS_SAVE_FAILED.getMessage(), { type: "error" }));
        return;
    }
    try {
        yield put(adminActions.isPersisting({ pageName: action.payload.pageName, isPersisting: true }));
        const pageResponse: Return<typeof adminService.saveFirmSettingsPageData> = yield adminService.saveFirmSettingsPageData(action.payload.pageName, action.payload.pageData);
        if (ok(pageResponse)) {
            //update admin state
            yield put(adminActions.saveFirmSettingsPageDataSuccess({ pageName: action.payload.pageName, pageData: action.payload.pageData }));
            yield put(adminActions.setIsPageEdited({ pageName: action.payload.pageName, isPageEdited: false }));
            yield put(userAlertActions.addUserAlert(`${FirmSettings.getDefinitionDisplayName(pageDefinition)} updated successfully.`, { type: "success" }));
        } else {
            yield put(adminActions.adminFailure(pageResponse.message));
            yield put(userAlertActions.addUserAlert(FirmSettings.Messages.FIRM_SETTINGS_SAVE_FAILED.getMessage(), { type: "error" }));
        }
    } catch (error) {
        logger.error(`Unable to save all page data for Firm Settings page ${action.payload.pageName}`, error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFailure(parseError(error)));
        yield put(userAlertActions.addUserAlert(FirmSettings.Messages.FIRM_SETTINGS_SAVE_FAILED.getMessage(), { type: "error" }));
    } finally {
        yield put(adminActions.isPersisting({ pageName: action.payload.pageName, isPersisting: false }));
    }
}


function* saveSanctionsList(action: Action<typeof AdminActionType.SAVE_SANCTIONS_LIST, ImportDataToPost>) {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();
    const pageDefinition = FirmSettings.validateDefinitionExists("integration-management");
    if (!ok(pageDefinition)) {
        //This should never happen unless something has seriously gone wrong.
        logger.error(`Page definition for page ${action.payload.entityName} does not exist. Error: ${pageDefinition.message}`);
        yield put(adminActions.adminFailure(`Page ${action.payload.entityName} could not be found. See inner error for details.`));
        yield put(userAlertActions.addUserAlert(FirmSettings.Messages.FIRM_SETTINGS_SAVE_FAILED.getMessage(), { type: "error" }));
        return;
    }
    try {
        yield put(adminActions.isPersisting({ pageName: "integration-management", isPersisting: true }));
        const pageResponse: Return<typeof adminService.saveSanctionsList> = yield adminService.saveSanctionsList(action.payload);
        if (ok(pageResponse)) {
            //update admin state
            yield put(adminActions.saveSanctionListSuccess( { entityName: action.payload.entityName }));
            yield put(userAlertActions.addUserAlert(FirmSettings.Messages.SANCTIONS_LIST_SAVE_SUCCEEDED.getMessage(action.payload.entityName), { type: "success" }));
        } else {
            yield put(adminActions.adminFailure(pageResponse.message));
             yield put(appActions.showError(FirmSettings.Messages.SANCTIONS_IMPORT_FILE_TOO_LARGE.getMessage(action.payload.entityName)));
            // yield put(userAlertActions.addUserAlert(FirmSettings.Messages.SANCTIONS_LIST_SAVE_FAILED.getMessage(action.payload.entityName), { type: "error" }));
        }
    } catch (error) {
        logger.error(`Unable to save all sanctions list for entity ${action.payload.entityName}`, error instanceof Error ? error.message : JSON.stringify(error));
        
        yield put(adminActions.adminFailure(parseError(error)));
        yield put(appActions.showError(FirmSettings.Messages.SANCTIONS_IMPORT_FAILURE.getMessage(action.payload.entityName)));
        //yield put(userAlertActions.addUserAlert(FirmSettings.Messages.SANCTIONS_LIST_SAVE_FAILED.getMessage(action.payload.entityName), { type: "error" }));
    } finally {
        yield put(adminActions.isPersisting({ pageName: "integration-management", isPersisting: false }));
    }
}

function* fetchRlsColumnVisibility() {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();

    try {
        yield put(adminActions.isFetching({ pageName: FirmSettings.InformationBarriersDefinition.name, dataName: "RLS_COLUMN_VISIBILITY", isFetching: true }));
        const columnVisibilityConfig: Result<string[], Forbidden> = yield adminService.fetchRlsColumnVisibility();
        if (ok(columnVisibilityConfig)) {
            yield put(adminActions.fetchRlsColumnVisibilitySuccess({ rlsVisibleColumns: columnVisibilityConfig }));
        } else {
            yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.InformationBarriersDefinition.name, dataName: "RLS_COLUMN_VISIBILITY", error: columnVisibilityConfig.message }));
            yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.InformationBarriersDefinition.displayName)));
        }
    } catch (error) {
        logger.error("Unable to fetch rls column visibility", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(adminActions.adminFetchFailure({ pageName: FirmSettings.InformationBarriersDefinition.name, dataName: "RLS_COLUMN_VISIBILITY", error: parseError(error) }));
        yield put(appActions.showError(FirmSettings.Messages.FIRM_SETTINGS_PAGE_LOAD_FAILED.getMessage(FirmSettings.InformationBarriersDefinition.displayName)));
    } finally {
        yield put(adminActions.isFetching({ pageName: FirmSettings.InformationBarriersDefinition.name, dataName: "RLS_COLUMN_VISIBILITY", isFetching: false }));
    }
}

function* saveRlsColumnVisibility(action: Action<typeof AdminActionType.SAVE_RLS_COLUMN_VISIBILITY, { rlsVisibleColumns: string[] }>) {
    const adminService = yield* SagaContext.getAdminService();
    const logger = yield* SagaContext.getLogger();
    try {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.InformationBarriersDefinition.name, isPersisting: true }));

        const savedFields: Result<string[], Forbidden> = yield adminService.saveRlsColumnVisibility(action.payload.rlsVisibleColumns);
        if (ok(savedFields)) {
            yield put(adminActions.setIsPageEdited({ pageName: FirmSettings.InformationBarriersDefinition.name, isPageEdited: false }));
            yield put(userAlertActions.addUserAlert(InformationBarriersMessages.SAVE_COMPLETE_SNACKBAR_SUCCESS.getMessage(), { type: "success" }));

            yield put(adminActions.saveRlsColumnVisibilitySuccess({ rlsVisibleColumns: savedFields }));
        } else {
            throw savedFields;
        }
    } catch (error) {
        logger.error("Unable to save secured column visibility", error instanceof Error ? error.message : JSON.stringify(error));
        yield put(userAlertActions.addUserAlert(InformationBarriersMessages.SAVE_COMPLETE_SNACKBAR_FAILURE.getMessage(), { type: "error" }));
        yield put(adminActions.adminFailure(parseError(error)));
    } finally {
        yield put(adminActions.isPersisting({ pageName: FirmSettings.InformationBarriersDefinition.name, isPersisting: false }));
    }
}
